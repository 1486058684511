import React, { useEffect } from 'react';
import { GetServerSideProps } from 'next';
import WebviewHandler from '@peoplefund/utils/webview-handler/index.util';
import { useRouter } from 'next/router';
import PageUrls from '@peoplefund/constants/page-urls';
import Main from '@peoplefund/components/index';
import Footer from '@peoplefund/components/common/Footer';
import GnbGnb from '@peoplefund/components/_app/GlobalLayoutHeaderProvider/GnbGnb';
interface Props {
  serverName: string;
  serverVersion: string;
}
const Index: React.FC<Props> = () => {
  const router = useRouter();
  const isMillion = WebviewHandler().appType === 'Million';
  useEffect(() => {
    /**
     * fyi. 크플 투자 웹뷰에서 메인페이지로 접근하는 경우, 크플 투자 메인으로 리다이렉트 시킨다.
     */
    if (isMillion) {
      router.replace(PageUrls.investing.CPLE_MAIN);
    }
  }, [router, isMillion]);
  return <>
			<GnbGnb data-sentry-element="GnbGnb" data-sentry-source-file="index.tsx" />
			<Main data-sentry-element="Main" data-sentry-source-file="index.tsx" />
			<Footer data-sentry-element="Footer" data-sentry-source-file="index.tsx" />
		</>;
};
export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {
      serverName: process.env.npm_package_name,
      serverVersion: process.env.npm_package_version
    }
  };
};
export default Index;