import React, { useEffect, useState } from 'react';
import * as Styled from './index.styled';
interface PreDateObj {
  num: number;
  unit: string;
}
interface CircleProps {
  b: number;
  w: number;
  r: number;
  x: number;
  y: number;
  baseStroke: string;
  baseFill: string;
  stroke: string;
}
const circle: CircleProps = {
  b: 5,
  w: 214,
  r: 105,
  x: 107,
  y: 107,
  baseStroke: 'rgba(255, 255, 255, 0.3)',
  baseFill: 'rgba(255, 255, 255, 0.2)',
  stroke: 'rgba(255, 255, 255, 1)'
};
export interface CircleDataProps {
  fund_start_datetime: string;
  loan_application_status: string;
  percent: number;
  investment_count: number;
}
function PieChart({
  data
}: {
  data?: CircleDataProps;
}) {
  const radius = 105;
  const circumference = 2 * Math.PI * radius;
  const [isWaiting, setIsWaiting] = useState(false);
  const [preDateObj, setPreDateObj] = useState<PreDateObj[]>([]);
  const [percent, setPercent] = useState(data?.percent || 0);
  const [status, setStatus] = useState('모집중');
  console.log({
    setIsWaiting,
    setPreDateObj,
    setPercent
  });
  useEffect(() => {
    if (!data) {
      return;
    }
    // 상태 확인 및 업데이트
    switch (data.loan_application_status) {
      case '투자모집마감':
      case '실행대기중':
        setStatus('모집완료');
        break;
      default:
        setStatus('모집중');
        break;
    }

    // 타이머 동작
    if (new Date().getTime() < new Date(data.fund_start_datetime).getTime()) {
      const timer = setInterval(() => {
        // const updatedPreDateObj = checkPreStart(data.fund_start_datetime);
        // setPreDateObj(updatedPreDateObj);
      }, 1000);
      return () => clearInterval(timer); // Cleanup timer
    }
  }, [data]);
  if (!data) {
    return <></>;
  }
  return <Styled.PieWrapper.Wrapper data-sentry-element="unknown" data-sentry-component="PieChart" data-sentry-source-file="PieChart.tsx">
			{isWaiting ? <svg width={circle.w} height={circle.w} xmlns="http://www.w3.org/2000/svg">
					<g>
						<circle className="circle-base" r={circle.r} cy={circle.y} cx={circle.x} strokeWidth={circle.b} stroke={circle.baseStroke} fill={circle.baseFill} />
					</g>
				</svg> : <Styled.StyledSVG xmlns="http://www.w3.org/2000/svg">
					<g>
						<Styled.CircleBase r={radius} cx="107" cy="107" />
						<Styled.CircleFill percent={percent} circumference={circumference} r={radius} cx="107" cy="107" />
					</g>
				</Styled.StyledSVG>}
			<Styled.PieWrapper.Content data-sentry-element="unknown" data-sentry-source-file="PieChart.tsx">
				{isWaiting ? <>
						{preDateObj.map((obj, index) => <span key={index} className="g">
								<span className="num">{obj.num}</span>
								<span className="unit">{obj.unit}</span>
							</span>)}
					</> : <>
						<span className="status">{status}</span>
						<span className="rate">
							<span className="num">{percent.toFixed(1)}</span>%
						</span>
						<span className="count">
							<span className="num">{data.investment_count}</span>명 참여
						</span>
					</>}
			</Styled.PieWrapper.Content>
		</Styled.PieWrapper.Wrapper>;
}
export default PieChart;