import React from 'react';
import { IcTooltip } from '@peoplefund/components/common/Icon';
import * as Styled from './index.styled';
import { BasicTooltip, BasicTooltipProps } from '@pfct/purple';
import { zIndex } from '@peoplefund/styles/global.constants';
export type ToolTipWithQuestionMarkIconProps = Omit<BasicTooltipProps, 'children'>;
const ToolTipWithQuestionMarkIcon: React.FC<ToolTipWithQuestionMarkIconProps> = ({
  content,
  ...restProps
}) => {
  return <Styled.ToolTipWithQuestionMarkIcon data-sentry-element="unknown" data-sentry-component="ToolTipWithQuestionMarkIcon" data-sentry-source-file="index.tsx">
			<BasicTooltip {...restProps} content={content} zIndex={zIndex.gnb - 1} data-sentry-element="BasicTooltip" data-sentry-source-file="index.tsx">
				<IcTooltip data-sentry-element="IcTooltip" data-sentry-source-file="index.tsx" />
			</BasicTooltip>
		</Styled.ToolTipWithQuestionMarkIcon>;
};
export default ToolTipWithQuestionMarkIcon;